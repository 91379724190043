<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import renameFirewall from '@/mixins/renameFirewall';
import changeFirewallDescription from '@/mixins/changeFirewallDescription';
export default {
  name: 'FirewallContextMenu',
  mixins: [renameFirewall, showErrorModal, changeFirewallDescription],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    // isLoading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    serverId() {
      return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    id() {
      return this.tariff.id;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const arr = [];
      if (this.tariff.name !== 'default')
        arr.push([
          {
            key: 'descr',
            handler: 'descr',
          },
          {
            key: 'edit',
            id: this.id,
            handler: 'func',
            action: {
              network: {
                name: this.tariff.name,
              },
            },
          },
          {
            key: 'delete',
            color: 'del',
            // disabled: this.tariff.name === 'default',
            handler: 'delete',
            action: 'delete',
          },
        ]);
      return arr;
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'delete') this.deleteFirewall(item);
      if (item.action && item.key === 'edit') this.renameFirewall(this.tariff.name, item);
      if (item.handler === 'descr') this.changeFirewallDescription(this.tariff);
    },
    deleteFirewall() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('delete') + this.id + '?',
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentFirewall(this.id).then(async data => {
                  // console.log(data);
                  await this.$store.dispatch(
                    'moduleStack/fetchOpenStackRequestFirewalls',
                    'firewalls'
                  );
                });
                res = 'success';
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.text = this.$t(`modal.sure.${res}`);
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close'), color: 'error' },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store.dispatch(
                            'moduleStack/fetchOpenStackRequestFirewalls',
                            'firewalls'
                          );
                          this.$router.push({ name: 'viewStackFirewall' }).catch(() => {});
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteCurrentFirewall(firewall) {
      return this.$store
        .dispatch('moduleStack/deleteFirewall', {
          firewall: firewall,
        })
        .catch(e => this.showError(e));
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить группу:",
    "actions": {
      "edit": "Переименовать",
      "false": "Выключить",
      "true": "Включить",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить группу",
      "descr": "Изменить описание",
      "confirm" : "Сменить"

    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "description": "Описание файрвола:",
      "sure": {
        "text": "Вы собираетесь %{msg} группу. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "true": "включена",
        "false": "выключена",
        "success": "Группа успешно удалена.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
